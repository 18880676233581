.chatbot-container {
  position: fixed;
  width: 90%;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--border-radius);
  overflow: hidden;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.chatbot-container:hover .chatbot-alpha-overlay {
  opacity: 1;
}

.chatbot-text {
  color: var(--white);
  font-size: var(--font-size-large);
  text-align: center;
  font-weight: bold;
}

/* Chatbotmenu */
.chat-menu {
  padding: 1rem;
}

.chat-header {
  color: var(--white);
  padding: 12px;
  text-align: center;
  font-size: var(--font-size-large);
  font-weight: bold;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  flex-shrink: 0;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  height: 40vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  scrollbar-width: thin;
  scrollbar-color: var(--wheat) rgba(0, 0, 0, 0.2);
  background-color: #00000040;
  border-radius: 0.3rem;
  border: 0.07rem solid rgba(255, 255, 255, 0.4);
}

.chat-messages::-webkit-scrollbar {
  width: 5px;
}

.chat-bubble {
  max-width: 75%;
  padding: 0.5rem 0.7rem;
  border-radius: var(--border-radius);
  font-size: var(--font-size-medium);
  word-wrap: break-word;
}

.chat-bubble.ai {
  background: linear-gradient(90deg, var(--transparent), var(--purple) 70%);
  color: var(--white);
  align-self: flex-start;
  border-radius: var(--radius-right);
}

.chat-bubble.user {
  background: linear-gradient(90deg, var(--cyan), var(--transparent) 100%);
  color: var(--white);
  align-self: flex-end;
  border-radius: var(--radius-left);
}

.chat-input-container {
  display: flex;
  gap: 0.5rem;
  padding-top: 1rem;
  flex-shrink: 0;
}

.chat-input {
  width: 70%;
  background-color: #00000040;
  border-radius: 0.3rem;
  border: 0.07rem solid rgba(255, 255, 255, 0.4);
  outline: none;
  color: var(--white);
  font-weight: bold;
  font-size: 1rem;
}

.send-button {
  width: 30%;
  background-color: #00000040;
  border-radius: 0.3rem;
  border: 0.07rem solid rgba(255, 255, 255, 0.4);
  outline: none;
  color: var(--white);
  font-weight: bold;
  font-size: 1rem;
}

.send-button:disabled {
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .chat-container {
    height: 600px;
  }

  .chat-messages {
    height: 300px;
  }

  .chat-header {
    font-size: var(--font-size-medium);
  }
}
