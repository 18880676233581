/* Menu container */
.menu {
  width: 19rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  transform: translateY(-60%);
  left: 10vw;
}

/* Individual menu item */
.item {
  height: 3.7rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

/* Title within each menu item */
.title {
  font-size: 1rem;
  padding-right: 2.5rem;
}

/* Active menu item title size */
.item.active .title {
  font-size: 1.5rem;
}

/* Sub-container for subheadings */
.sub-container {
  width: 18.5rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 32%;
  right: 10vw;
  cursor: pointer;
}

/* Style for active subheading */
.active-subheading {
  cursor: default;
}

/* Background gradient for active menu items */
.item.active:nth-child(1) {
  background: linear-gradient(90deg, var(--transparent), var(--purple) 70%);
  border-radius: var(--radius-right);
}
.item.active:nth-child(2) {
  background: linear-gradient(90deg, var(--transparent), var(--blue) 70%);
  border-radius: var(--radius-right);
}
.item.active:nth-child(3) {
  background: linear-gradient(90deg, var(--transparent), var(--cyan) 70%);
  border-radius: var(--radius-right);
}

/* Border radius for active subheading titles */
.sub-container-1.active-subheading h3,
.sub-container-2.active-subheading h3,
.sub-container-3.active-subheading h3 {
  border-radius: var(--radius-left);
}

/* Background gradient for active subheading titles */
.sub-container-1.active-subheading h3 {
  background: linear-gradient(90deg, var(--purple), var(--transparent) 100%);
}
.sub-container-2.active-subheading h3 {
  background: linear-gradient(90deg, var(--blue), var(--transparent) 100%);
}
.sub-container-3.active-subheading h3 {
  background: linear-gradient(90deg, var(--cyan), var(--transparent) 100%);
}

/* Default cursor and animation for active items */
.item.active {
  cursor: default;
}

/* Container for subheading content, hidden by default */
.p-container {
  display: none;
}

/* Show subheading content when active */
.active-subheading .p-container {
  display: block;
  font-size: 1rem;
}

/* Container for icon and title */
.icon-title-container {
  display: flex;
  gap: 1.2rem;
  margin-bottom: 0.5rem;
  cursor: default;
}

/* Icon style */
.icon {
  width: 3rem;
  height: 3rem;
}

/* Subheading styles */
.sub-container-1 h3,
.sub-container-2 h3,
.sub-container-3 h3 {
  margin: 0.2rem 0;
  padding: 0.5rem 0 0.5rem 1rem;
}

/* Media query for screens with max-width of 1024px */
@media (max-width: 1024px) {
  .menu {
    width: 15rem;
    top: 40%;
    left: 50%;
  }
  .item {
    height: 2.5rem;
  }
  .sub-container {
    width: 18rem;
    top: 50%;
    right: 1rem;
    left: auto;
    transform: translateX(-110%);
  }
  .title {
    font-size: 0.8rem;
  }
  .active-subheading .p-container {
    font-size: 0.8rem;
  }
  .icon-title-container,
  .icon {
    display: none;
  }
}

/* Media query for screens with max-width of 768px */
@media (max-width: 768px) {
  .menu {
    width: 10rem;
    top: 25%;
    left: 40%;
  }
  .item {
    height: 2rem;
  }
  .item.active .title {
    font-size: 1rem;
  }
  .title {
    font-size: 0.8rem;
  }
  .sub-container {
    width: 10rem;
    top: 35%;
    left: 35%;
    transform: translateX(-50%);
  }
  .active-subheading .p-container {
    font-size: 0.8rem;
  }
  .icon-title-container,
  .icon {
    display: none;
  }
}
